<template>
  <div>
    <v-card flat class="segmented">
      <v-card-text>
        <v-row v-if="searchable" class="mb-4">
          <v-col cols="12" md="4">
            <v-text-field
              v-model="keyword"
              solo
              flat
              hide-details
              :label="$t('searchKeywordPlaceholder')"
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-select
              v-model="statusFilter"
              :items="statusOptions"
              solo
              flat
              hide-details
              multiple
              clearable
              chips
              deletable-chips
              :label="$t('feedbacks.filterByStatus')"
            >
            </v-select>
          </v-col>

          <v-col cols="12" md="4">
            <v-select
              v-model="talkroundId"
              solo
              flat
              hide-details
              :items="talkroundItems"
              :label="$t('feedbacks.talkround')"
              :disabled="loading"
            ></v-select>
          </v-col>
        </v-row>

        <div
          v-if="selectedFeedbacks && selectedFeedbacks.length > 0"
          class="mb-12"
        >
          <h4>Ausgewählte Prozesse</h4>
          <v-row>
            <v-col cols="12">
              <process-table
                :processes="selectedFeedbacks"
                :loading="loading"
                :allowActions="true"
                @openForm="openForm"
              ></process-table>
            </v-col>
          </v-row>
        </div>

        <v-row>
          <v-col cols="12">
            <process-table
              :processes="unselectedFeedbacks"
              :keyword="keyword"
              :statusFilter="statusFilter"
              :loading="loading"
              paged
              @openForm="openForm"
            ></process-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="feedbackDialog" persistent max-width="1024px">
      <v-card class="white">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="feedbackDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-skeleton-loader
            v-if="feedbackLoading"
            type="paragraph@3, text@6, paragraph@2, actions"
          ></v-skeleton-loader>

          <questionnaire
            v-else
            :form="feedbackData.formContent"
            :targets="feedbackData.targetItems"
            :feedbackId="feedbackData.id"
            :readOnly="isReadOnly"
            :canApprove="feedbackData.canApprove"
            :isComparison="feedbackData.isComparison"
            :comparisonItems="feedbackData.comparisonData"
            :comparisonTableItems="feedbackData.tableComparisonData"
            :loading="feedbackLoading"
            @submit="submit"
            @submissionComplete="submissionComplete"
            @errorOnSave="hasError = true"
          ></questionnaire>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="submitSnackbar"
      color="success"
      :timeout="snackbarTimeout"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-check-circle</v-icon>
        <v-layout column>
          <div>{{ $t('toast.dataSubmitted') }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { feedbackEnums } from '@/enums/feedbacks.js';

import processTable from '@/components/feedbacks/pdc/pdc-process-table.vue';
import talkroundService from '@/services/TalkroundService.js';
import questionnaire from '@/components/forms/questionnaire/questionnaire.vue';

export default {
  props: {
    searchable: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data: () => ({
    keyword: '',
    statusFilter: null,
    feedbacks: [],
    talkrounds: [],
    talkroundId: null,
    loading: true,
    currentFeedback: null,
    customSettingsLoaded: false,
    feedbackDialog: false,
    feedbackLoading: true,
    snackbarTimeout: 3000,
    submitSnackbar: false
  }),

  computed: {
    ...mapState('feedbacks', {
      feedbackData: 'currentFeedbackData'
    }),

    ...mapGetters('users', {
      users: 'getOverviewUsers'
    }),

    isReadOnly() {
      if (this.feedbackData === null) {
        return true;
      }

      if (this.feedbackData.isSubmitted && this.feedbackData.canUpdate) {
        return false;
      }

      if (!this.feedbackData.isSubmitted && this.feedbackData.canWrite) {
        return false;
      }

      return true;
    },

    selectedFeedbacks() {
      const items = this.feedbacks.filter((x) => x.selected);
      for (const item of items) {
        if (!item.sortIndex) {
          item.sortIndex = Number.MAX_VALUE;
        }
      }

      return items;
    },

    talkroundItems() {
      const typeId = feedbackEnums.processTypes.PDC;

      return this.talkrounds
        .filter((x) => x.processType == typeId)
        .map((x) => {
          const start = this.$d(new Date(x.startDateUtc), 'date');
          const end = this.$d(new Date(x.endDateUtc), 'date');

          return {
            value: x.id,
            text: `${start} - ${end}`
          };
        });
    },

    unselectedFeedbacks() {
      return this.feedbacks.filter((x) => !x.selected);
    },

    userOptions() {
      return this.users
        .map((x) => {
          return {
            text: `${x.firstname} ${x.lastname}`,
            value: x.id
          };
        })
        .sort((a, b) => a.text.localeCompare(b.text));
    },

    statusOptions() {
      if (!this.feedbacks) {
        return [];
      }

      let opts = [];
      for (let item of this.feedbacks) {
        if (opts.find((x) => x.value === item.statusId)) {
          continue;
        }

        const itemOption = {
          value: item.statusId,
          text: `${this.$t(
            `feedbacks.processTypes.${item.processType}`
          )} - ${this.localizeStatus(item.processType, item.statusId)}`
        };

        opts.push(itemOption);
      }

      return opts.sort((a, b) => a.value > b.value);
    }
  },

  watch: {
    async talkroundId() {
      this.keyword = '';
      this.statusFilter = null;

      this.loading = true;
      this.feedbacks = await this.listFeedbacks(this.talkroundId);
      this.loading = false;
    }
  },

  async mounted() {
    this.talkrounds = await this.listTalkrounds();
    this.talkroundId = this.getTalkroundId();

    this.loading = false;
  },

  methods: {
    ...mapActions({
      listFeedbacks: 'feedbacks/listPdcEdc',
      loadFeedback: 'feedbacks/loadFeedback',
      loadCustomSettings: 'forms/loadCustomSettings',
      submitFeedback: 'feedbacks/submitFeedback'
    }),

    getTalkroundId() {
      const typeId = feedbackEnums.processTypes.PDC;

      var processTalkrounds = this.talkrounds.filter(
        (x) => x.processType == typeId
      );

      if (processTalkrounds.length === 1) {
        return processTalkrounds[0].id;
      }

      var activeTalkrounds = processTalkrounds.filter((x) => x.isActive);
      if (activeTalkrounds.length > 0) {
        return activeTalkrounds[0].id;
      }

      return processTalkrounds[0].id;
    },

    async listTalkrounds() {
      const processType = feedbackEnums.processTypes.PDC;

      let talkrounds = await talkroundService.listTalkrounds();
      talkrounds = talkrounds.filter((x) => x.processType == processType);

      return talkrounds;
    },

    async openForm(feedback) {
      this.feedbackLoading = true;
      this.feedbackDialog = true;

      if (!this.customSettingsLoaded) {
        await this.loadCustomSettings('hays');
      }

      await this.loadFeedback(feedback.conclusionId);

      this.feedbackLoading = false;
    },

    submit() {
      this.submitFeedback(this.feedbackData.id);
    },

    submissionComplete(mode) {
      if (mode === 'submit') {
        this.submitSnackbar = true;
        this.feedbackDialog = false;
      }
    }
  },

  components: {
    processTable,
    questionnaire
  }
};
</script>
