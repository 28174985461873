<template>
  <div>
    <div class="table feedbacks-overview">
      <div class="table-header d-none d-md-flex">
        <v-row class="d-flex">
          <v-col cols="12" md="1" class="caption">
            {{ $t('employeeDetails.shorthand') }}</v-col
          >
          <v-col cols="12" md="2" class="caption">{{
            $t('employeeDetails.name')
          }}</v-col>
          <v-col cols="12" md="2" class="caption">{{
            $t('employeeDetails.hierarchy')
          }}</v-col>
          <v-col cols="12" md="2" class="caption">{{
            $t('employeeDetails.supervisor')
          }}</v-col>
          <v-col cols="12" md="1" class="caption">Director</v-col>
          <v-col cols="12" md="1" class="caption">Managing Director</v-col>
          <v-col cols="12" md="2" class="caption">{{
            $t('feedbacks.status')
          }}</v-col>
          <v-col cols="12" md="1" class="caption text-center">{{
            $t('forms.formActions')
          }}</v-col>
        </v-row>
      </div>

      <div class="table-body overview-body">
        <template v-if="!loading && processes && processes.length > 0">
          <process-item
            v-for="item in visibleProcesses"
            :key="`proces_item_${item.id}`"
            :process="item"
            :keyword="keyword"
            :allowActions="allowActions"
            @sortUp="sortUp(item)"
            @sortDown="sortDown(item)"
            @toggleSelection="toggleSelection($event, item)"
            @openForm="$emit('openForm', item)"
          ></process-item>
        </template>

        <div v-else-if="loading" class="mt-4">
          <v-row>
            <v-col cols="12">
              <v-progress-linear indeterminate></v-progress-linear>
            </v-col>
          </v-row>
        </div>
        <div v-else class="mt-4">
          <v-row>
            <v-col cols="12">
              <v-alert text type="info">
                {{ $t('feedbacks.noActiveProcess') }}
              </v-alert>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>

    <v-row v-if="allowActions" class="mt-4">
      <v-col cols="12" class="d-flex justify-end">
        <v-btn @click="unselectAll" class="mr-2">
          <v-icon>mdi-minus-box-outline</v-icon>
          {{ $t('unselectAll') }}
        </v-btn>

        <v-btn
          @click="
            download(getPdcProcessDownloadUrl(), 'PDC_Export.html', 'text/html')
          "
        >
          <v-icon class="mr-2">mdi-printer</v-icon>
          {{ $t('feedbacks.printDocuments') }}
        </v-btn>

        <!-- <v-btn class="ml-4">
          <v-icon class="mr-2">mdi-calendar</v-icon>
          Termin festlegen
        </v-btn> -->
        <v-menu
          v-model="showDatetimePicker"
          :close-on-content-click="false"
          :nudge-left="290"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on: onMenu }">
            <v-btn color="default" v-on="onMenu" class="ml-2">
              <v-icon small class="mr-2">mdi-calendar</v-icon>
              {{ $t('feedbacks.setTalkDate') }}
            </v-btn>
          </template>

          <date-time-picker
            ref="dateTimePicker"
            :date="talkDate"
            @selected="talkDateSelected"
          ></date-time-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row v-if="paged" v-show="numPages > 1">
      <v-col cols="12">
        <div class="text-center">
          <v-pagination
            v-model="pageIndex"
            :length="numPages"
            :total-visible="7"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import api from '@/helpers/api.js';
import processItem from '@/components/feedbacks/pdc/pdc-process-item.vue';
import dateTimePicker from '@/components/datetime-picker.vue';

export default {
  props: {
    processes: {
      type: Array,
      required: true
    },

    keyword: {
      type: String,
      required: false,
      default: ''
    },

    statusFilter: {
      type: Array,
      required: false,
      default: () => []
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    },

    allowActions: {
      type: Boolean,
      required: false,
      default: false
    },

    paged: {
      type: Boolean,
      required: false,
      default: false
    },

    pageSize: {
      type: Number,
      required: false,
      default: 50
    }
  },

  data: () => ({
    maxSortIndex: 0,
    pageIndex: 1,
    showDatetimePicker: false,
    talkDate: null
  }),

  computed: {
    filteredProcesses() {
      let items = this.processes;
      if (this.statusFilter && this.statusFilter.length > 0) {
        items = items.filter((x) => this.statusFilter.includes(x.statusId));
      }

      const kw = this.keyword.toLowerCase();

      if (this.keyword && this.keyword !== '') {
        items = items.filter((x) => {
          return (
            x.recipientShorthand.toLowerCase().indexOf(kw) > -1 ||
            x.recipientName.toLowerCase().indexOf(kw) > -1 ||
            x.donorShorthand?.toLowerCase().indexOf(kw) > -1 ||
            x.directorShorthand?.toLowerCase().indexOf(kw) > -1 ||
            x.managingDirectorShorthand?.toLowerCase().indexOf(kw) > -1 ||
            (x.talkDate && this.$d(new Date(x.talkDate), 'datetime').indexOf(kw) > -1)
          );
        });
      }

      return items;
    },

    numPages() {
      return Math.ceil(this.filteredProcesses.length / this.pageSize);
    },

    sortedProcesses() {
      const vm = this;
      return [...this.filteredProcesses].sort((a, b) => {
        if (vm.allowActions) {
          return a.sortIndex - b.sortIndex;
        } else {
          return a.recipientName.localeCompare(b.recipientName);
        }
      });
    },

    visibleProcesses() {
      if (!this.paged) {
        return this.sortedProcesses;
      }

      const startItem = (this.pageIndex - 1) * this.pageSize;
      const endItem = Math.min(
        startItem + this.pageSize,
        this.sortedProcesses.length
      );
      return this.sortedProcesses.slice(startItem, endItem);
    }
  },

  watch: {
    numPages() {
      if (this.numPages < this.pageIndex) {
        this.pageIndex = Math.max(1, this.numPages);
      }
    }
  },

  methods: {
    ...mapActions({
      saveTalkDate: 'feedbacks/saveTalkDate'
    }),

    async download(filePath, fileName, mimeType) {
      var file = await api.download(filePath);
      const blob = new Blob([file], {
        type: mimeType
      });

      const fileUrl = window.URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      document.body.appendChild(anchor);
      anchor.href = fileUrl;
      anchor.download = fileName;
      anchor.click();

      window.URL.revokeObjectURL(fileUrl);
    },

    getPdcProcessDownloadUrl() {
      let queryParam = '';
      for (const feedbackId of this.sortedProcesses.map(
        (x) => x.donorEvaluationId
      )) {
        if (queryParam !== '') {
          queryParam += '&';
        }

        queryParam += `feedbackIds=${feedbackId}`;
      }

      return `/downloadMultiple/pdc/feedback?${queryParam}`;
    },

    sortUp(item) {
      if (item.sortIndex === 1) {
        return;
      }

      const swapIndex = item.sortIndex - 1;
      const swapItem = this.processes.find((x) => x.sortIndex === swapIndex);

      swapItem.sortIndex = item.sortIndex;
      item.sortIndex = swapIndex;
    },

    sortDown(item) {
      if (item.sortIndex === this.processes.length) {
        return;
      }

      const swapIndex = item.sortIndex + 1;
      const swapItem = this.processes.find((x) => x.sortIndex === swapIndex);

      swapItem.sortIndex = item.sortIndex;
      item.sortIndex = swapIndex;
    },

    talkDateSelected(selectedDate) {
      this.showDatetimePicker = false;

      const isoTalkDate = selectedDate.toISOString();
      this.talkDate = isoTalkDate;

      for (const p of this.processes) {
        this.saveTalkDate({
          processId: p.id,
          talkDateUtc: isoTalkDate
        });

        p.talkDate = selectedDate;
      }

      this.talkDate = null;
    },

    toggleSelection(isSelected, item) {
      if (isSelected) {
        item.selected = true;
        item.sortIndex = this.processes.length;
      } else {
        item.selected = false;
        item.sortIndex = Number.MAX_VALUE;
      }
    },

    unselectAll() {
      this.processes.forEach((x) => (x.selected = false));
    }
  },

  components: {
    processItem,
    dateTimePicker
  }
};
</script>
