<template>
  <v-row class="d-flex align-center">
    <v-col cols="12" md="1" class="d-flex">
      <v-simple-checkbox v-model="isSelected" :ripple="false">
      </v-simple-checkbox>
      <span
        v-html="$options.filters.highlight(process.recipientShorthand, keyword)"
      ></span>
    </v-col>
    <v-col cols="12" md="2">
      <span
        v-html="$options.filters.highlight(process.recipientName, keyword)"
      ></span>
    </v-col>
    <v-col cols="12" md="2">
      <span
        v-html="
          $options.filters.highlight(localize(process.hierarchy), keyword)
        "
      ></span>
    </v-col>
    <v-col cols="12" md="2">
      <span
        v-html="$options.filters.highlight(process.donorShorthand, keyword)"
      ></span>
    </v-col>
    <v-col cols="12" md="1">
      <span
        v-html="$options.filters.highlight(process.directorShorthand, keyword)"
      ></span>
    </v-col>
    <v-col cols="12" md="1">
      <span
        v-html="
          $options.filters.highlight(process.managingDirectorShorthand, keyword)
        "
      ></span>
    </v-col>
    <v-col cols="12" md="2">
      <v-chip
        label
        :color="getStatusColor(process.statusId, process.processType)"
      >
        {{ localizeStatus(process.processType, process.statusId) }}
      </v-chip>

      <div v-if="process.talkDate" class="mt-2">
        <v-icon class="mr-1">mdi-calendar</v-icon>
        <span v-html="$options.filters.highlight(dateString, keyword)"></span>
      </div>
    </v-col>
    <v-col cols="12" md="1" class="d-flex justify-center">
      <template v-if="allowActions">
        <v-btn small icon @click="sortUp">
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>

        <v-btn small icon @click="sortDown">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>

      <v-btn small icon @click="openForm" :disabled="protocolDisabled">
        <v-icon v-if="protocolSubmitted">mdi-clipboard-check-outline</v-icon>
        <v-icon v-else-if="protocolDisabled"
          >mdi-clipboard-remove-outline</v-icon
        >
        <v-icon v-else>mdi-clipboard-check-outline</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import processMixins from '@/mixins/feedback-process.js';

import { feedbackEnums } from '@/enums/feedbacks.js';

export default {
  props: {
    process: {
      type: Object,
      required: true
    },

    keyword: {
      type: String,
      required: false,
      default: ''
    },

    allowActions: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  mixins: [processMixins],

  data: () => ({
    isSelected: false
  }),

  computed: {
    dateString() {
      if (!this.process.talkDate) {
        return '';
      }

      return this.$d(new Date(this.process.talkDate), 'datetime');
    },

    protocol() {
      return this.process?.feedbacks?.find(
        (x) => x.feedbackType === feedbackEnums.feedbackTypes.DOCUMENTATION
      );
    },

    protocolDisabled() {
      if (this.protocol) {
        return !this.protocol.canWrite && !this.protocol.submitted;
      }

      return true;
    },

    protocolSubmitted() {
      if (this.protocol) {
        return this.protocol.submitted;
      }

      return false;
    }
  },

  watch: {
    isSelected() {
      this.$emit('toggleSelection', this.isSelected);
    }
  },

  mounted() {
    this.isSelected = this.process.selected;
  },

  methods: {
    sortUp() {
      this.$emit('sortUp');
    },

    sortDown() {
      this.$emit('sortDown');
    },

    openForm() {
      this.$emit('openForm');
    }
  }
};
</script>
